<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h1>Configuração</h1>
        <v-form lazy-validation>
          <v-autocomplete
            v-model="configuracao.estadoId"
            label="Estado"
            :items="estados"
            item-text="display"
            item-value="id"
            :rules="rules"
          />

          <v-autocomplete
            v-model="configuracao.tipoFreteId"
            label="Tipo"
            :items="tipos"
            item-text="nome"
            item-value="tipoFreteId"
            :rules="rules"
          />

          <v-text-field
            v-model="configuracao.pesoMaximo"
            label="Peso Máximo (gramas)"
            name="pesoMaximo"
            type="number"
            :rules="rules"
          />

          <v-text-field
            v-model="configuracao.preco"
            label="Preço"
            name="preco"
            type="number"
            :rules="rules"
          />

          <v-text-field
            v-model="configuracao.valorMinimoPedido"
            label="Valor Mínimo do Pedido"
            name="valorMinimoPedido"
            type="number"
          />

          <v-btn
            color="primary"
            @click="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'CategoriaForm',
    components: {
    //
    },
    data: () => ({
      estados: [],
      tipos: [],
      profiles: [],
      configuracao: {},
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
      this.$http.get('/endereco/estado')
        .then(resp => {
          this.estados = resp.data
        })
      this.$http.get('/fretes/tipos')
        .then(resp => {
          this.tipos = resp.data
        })
    },
    methods: {
      load () {
        this.$http.get(`/fretes/${this.$route.params.id}`)
          .then(resp => {
            this.configuracao = resp.data
            this.passwordRules = []
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        this.configuracao.pesoMaximo = parseFloat(this.configuracao.pesoMaximo)
        this.configuracao.preco = parseFloat(this.configuracao.preco)
        this.configuracao.valorMinimoPedido = parseFloat(this.configuracao.valorMinimoPedido)
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/fretes', this.configuracao)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/configurador-frete')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        this.$http.put('/fretes', this.configuracao)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/configurador-frete')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>
